import axios from "axios";
import { configs } from "../constants";
import Cookies from "universal-cookie";

export async function getAllCenterLocation() {
  const cookies = new Cookies();
  try {
    const res = await axios.get(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/all_center_location`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res.data;
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function getModeOfLearning() {
  const cookies = new Cookies();
  try {
    const res = await axios.get(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/get_mode_of_learnings`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res.data;
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function getAllLocationPage(id) {
  const cookies = new Cookies();
  try {
    const res = await axios.get(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/locationSchema?name=${id}`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res.data;
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
}

export async function LocationEditApi(id, data) {
  const cookies = new Cookies();
  let aid = cookies.get("aid", { domain: configs.DOMAIN, path: "/" });
  console.log(aid, "receive");
  try {
    return await axios.post(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/location_edit_field${id}`,
      { ...data, aid: aid },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );
  } catch (error) {
    return error.response;
  }
}

export async function CreateLocationApi(data) {
  const cookies = new Cookies();
  let aid = cookies.get("aid", { domain: configs.DOMAIN, path: "/" });
  console.log(aid, "receive");
  try {
    return await axios.post(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/create_location_page`,
      { data },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );
  } catch (error) {
    return error.response;
  }
}

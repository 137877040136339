import axios from "axios";
import { configs } from "../constants";
import Cookies from "universal-cookie";

export const marketingApiHelper = async (data) => {
  const cookies = new Cookies();

  try {
    console.log({ configs });
    const response = await axios.post(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/program_schema`,
      data,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );
    if (response.data === "Already Exists") {
      alert("Already Exists");
    } else if (response.data === "Succesfully added") {
      alert("Succesfully added");
    }
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.response) {
      return Promise.reject(error.response.data.message);
    }
    return Promise.reject("Something went wrong");
  }
};
export async function SendExperienceData(data) {
  try {
    const cookies = new Cookies();
    let aid = cookies.get("aid", { domain: configs.DOMAIN, path: "/" });
    // Fetch data from external API
    return await axios.post(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/update-haptik-program-name`,
      {
        ...data,
        aid: aid,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );
  } catch (error) {
    console.log(error, "error from edit api");
    return Promise.reject(error);
  }
}

export const leadApiHelper = async (data) => {
  try {
    // console.log(process.env.NEXT_ENV)
    // const response=await axios.post(`${configs.API_ENDPOINT}/leadsquare/lead`,data);
    const response = await axios.post(
      `${configs.API_ENDPOINT}/leadsquare/lead`,
      data
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
    if (error.response) {
      return Promise.reject(error.response.data.message);
    }
    return Promise.reject("Something went wrong");
  }
};
export const sendEventToLS = async (data) => {
  const cookies = new Cookies();
  let aid = cookies.get("aid", { domain: configs.DOMAIN, path: "/" });
  try {
    const response = await axios.post(
      `${configs.API_ENDPOINT}/leadsquare/send_event_to_ls`,
      {
        ...data,
        aid: aid,
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    if (error.response) {
      return Promise.reject(error.response.data.message);
    }
    return Promise.reject("Something went wrong");
  }
};
